// // import * as XLSX from "xlsx";
// // import Grid from "@mui/material/Grid";
// // import Card from "@mui/material/Card";
// // import MDBox from "components/MDBox";
// // import MDTypography from "components/MDTypography";
// // import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// // import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// // import Footer from "examples/Footer";
// // import DataTable from "examples/Tables/DataTable";
// // import { useNavigate } from "react-router-dom";
// // import { useEffect, useRef, useState } from "react";
// // import { axiosInstanceAuth } from "../../apiInstances/index";
// // import useEncryption from "EncryptDecrypt/EncryptDecrypt";
// // import Pagination from "@mui/material/Pagination";
// // import Stack from "@mui/material/Stack";
// // import { ToastContainer, toast } from "react-toastify";
// // import "react-toastify/dist/ReactToastify.css";
// // import { TextField } from "@mui/material";
// // import MDButton from "components/MDButton";

// // import "./user.css";
// // import input from "assets/theme/components/form/input";
// // import { array } from "prop-types";

// // const User = () => {
// //   const { encryptData, decryptData } = useEncryption();
// //   let navigate = useNavigate();
// //   const [searchInput, setSearchInput] = useState("");
// //   const [currentPage, setCurrentPage] = useState(1);
// //   console.log("🚀 ~ file: index.js:30 ~ User ~ currentPage:", currentPage)
// //   const [entriesPerPage] = useState(100);
// //   const [page, setPage] = useState(1);
// //   console.log("🚀 ~ file: index.js:30 ~ User ~ page:", page)
// //   const [requestData, setRequestData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [loading1, setLoading1] = useState();
// //   const [searchData,setsearchData]=useState()
// //     const [searchTerm, setSearchTerm] = useState();

// //   // console.log("🚀 ~ file: index.js:33 ~ User ~ loading1:", loading)

// //   const [totalstaked,totalStaked]=useState()
// //   const viewUser = (data) => {
// //     const queryParams = new URLSearchParams({
// //       walletAddress: data.data.walletAddress,
// //       createdAt: data.data.createdAt,
// //       updatedAt: data.data.updatedAt,
// //       id: data.data._id,
// //     });

// //     navigate(`/admin/viewUsers?${queryParams.toString()}`);
// //   };
// //   const dataa= localStorage.getItem('Token');
// //   // console.log("dataa",dataa);
// //   const getUser = async () => {
// //     setLoading(true)
// //     try {
// //       axiosInstanceAuth
// //         .get(`/usersData/${currentPage}`)
// //         .then((res) => {
// //           const responseData = decryptData(res?.data?.data);
// //           // console.log("🚀 ~ file: index.js:54 ~ .then ~ responseData============>:", responseData);
// //          const totalPages = responseData?.data?.totalPages;
// //          setPage(totalPages)
// //          setLoading(false)
// //         //  console.log("🚀 ~ file: index.js:57 ~ .then ~ totalPages:", totalPages)

// //           const start = (currentPage - 1) * entriesPerPage;
// //           const end = start + entriesPerPage;
// //           const temp = responseData?.data?.array?.map((data, index) => {
// //             // console.log("🚀 ~ file: index.js:59 ~ temp ~ data:", data?.data?.walletAddress)
// //             return {

// //               id: (
// //                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //                   {start+ index + 1}
// //                 </MDTypography>
// //               ),
// //               wallet_address: (
// //                 <div className="userDetails" onClick={() => viewUser(data)}>
// //                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //                     {`${data?.data?.walletAddress} `}
// //                   </MDTypography>
// //                 </div>
// //               ),
// //               refered_by: (
// //                 <div className="userDetails" onClick={() => viewUser(data)}>
// //                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //                     {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(-2)} `}
// //                   </MDTypography>
// //                 </div>
// //               ),
// //               staked_amounts: (
// //                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //                   {data?.total}
// //                 </MDTypography>
// //               ),
// //               staking_profit: (
// //                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
// //                   {data?.data?.profit}
// //                 </MDTypography>
// //               ),
// //               referaal_profit: (
// //                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
// //                   {data?.data?.refaralProfit}
// //                 </MDTypography>
// //               ),
// //               Block_Withdraw: (
// //                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
// //                   <MDButton
// //                     variant="gradient"
// //                     color="info"
// //                     fullWidth
// //                     type="submit"
// //                     className="TitleColor"
// //                     onClick={() => blockWithdraw(data.data._id)}
// //                   >
// //                     {data?.data?.blockWithdraw}
// //                   </MDButton>
// //                 </MDTypography>
// //               ),

// //             };

// //           });

// //           // console.log("temp---",temp)

// //           const calculateTotalStakedAmounts = (data) => {
// //             return data.reduce((total, item) => total + item.total, 0);
// //           };
// //           const totalStakedAmounts = calculateTotalStakedAmounts(responseData.data.array);

// //           totalStaked(totalStakedAmounts)

// //           setRequestData(temp);
// //           setLoading(false);
// //           // setPage(Math.ceil(responseData.data.array.length / entriesPerPage));

// //         })
// //         .catch((error) => {
// //           // toast.error(error.message);
// //           toast.error("something went wrong");

// //         });
// //     } catch (error) {
// //       console.error(error);
// //     }
// //   };

// //   const blockWithdraw = async (data) => {
// //     try {
// //       axiosInstanceAuth
// //         .post(`blockWECwithdraw/${data}`)
// //         .then(async (res) => {
// //           const responseData = decryptData(res?.data?.data);
// //           toast.success(responseData.message);
// //           getUser();
// //         })
// //         .catch((res) => {
// //           toast.error(res.data.message);
// //         });
// //     } catch (error) {
// //       console.error(error);
// //     }
// //   };

// //   const columns = [
// //     { Header: "id", accessor: "id", align: "left" },
// //     { Header: "wallet_address", accessor: "wallet_address", align: "left" },
// //     {Header: "refered_by",accessor: "refered_by", align: "left"},
// //     { Header: "staked_amounts", accessor: "staked_amounts", align: "left" },
// //     { Header: " staking_profit", accessor: "staking_profit", align: "left" },
// //     { Header: "referaal_profit", accessor: "referaal_profit", align: "left" },
// //     { Header: "Block_Withdraw", accessor: "Block_Withdraw", align: "left" },
// //   ];

// //   useEffect(() => {
// //     if (searchTerm == "") {
// //       getUser();
// //     }
// //   }, [searchTerm]);

// //   const handleInput = () => {

// //       try{

// //       axiosInstanceAuth.get(`/searchData`, {
// //         params: {
// //           walletAddress: searchTerm,
// //           page: currentPage,
// //         },
// //       }).then((res)=>{
// //         const responseData = decryptData(res?.data?.data)
// //         setsearchData(responseData)
// //         // console.log("🚀 ~ file: index.js:185 ~ handleInput ~ responseData:", responseData)

// //       // const searchData = searchInput?.filter((item) => {
// //       //   const walletAddress = item?.data?.walletAddress;
// //       //   return walletAddress?.includes(inputValue);
// //       // });

// //       const temp = responseData?.data?.array?.map((data, index) => {
// //         return {
// //           id: (
// //             <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //               {index + 1}
// //             </MDTypography>
// //           ),
// //           wallet_address: (
// //             <div className="userDetails" onClick={() => viewUser(data)}>
// //               <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //                 {`${data?.data?.walletAddress} `}
// //               </MDTypography>
// //             </div>
// //           ),
// //           refered_by: (
// //             <div className="userDetails" onClick={() => viewUser(data)}>
// //               <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //                 {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(-2)} `}
// //               </MDTypography>
// //             </div>
// //           ),
// //           staked_amounts: (
// //             <MDTypography component="a" variant="button" color="text" fontWeight="medium">
// //               {data?.total}
// //             </MDTypography>
// //           ),
// //           staking_profit: (
// //             <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
// //               {data?.data?.profit}
// //             </MDTypography>
// //           ),
// //           referaal_profit: (
// //             <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
// //               {data?.data?.refaralProfit}
// //             </MDTypography>
// //           ),
// //           Block_Withdraw: (
// //             <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
// //               <MDButton
// //                 variant="gradient"
// //                 color="info"
// //                 fullWidth
// //                 type="submit"
// //                 className="TitleColor"
// //                 onClick={() => blockWithdraw(data?.data?._id)}
// //               >
// //                 {data?.data?.blockWithdraw}
// //               </MDButton>
// //             </MDTypography>
// //           ),
// //         };
// //       });
// //       setRequestData(temp);
// //       setPage(1);
// //       setCurrentPage(1);
// //     })
// //     }
// //     catch (error) {
// //       console.error(error);
// //     }

// //   };

// //   const handlePageChange = (event,newPage) => {
// //     if (typeof newPage === "number") {
// //       setCurrentPage(newPage);
// //       console.log("new page: " + newPage);

// //     }
// //   };

// //   const downloadReport = () => {
// //   //  toast.success("Downloading Excel")
// //    setLoading(true)
// //    setLoading1(true)
// //     try {
// //       const encryptedData = encryptData();
// //       axiosInstanceAuth
// //       .get(`/usersData/${currentPage}`)
// //         .then((res) => {
// //           const responseData = decryptData(res.data.data);
// //           const formattedData = responseData.data.array.map((item) => ({
// //             walletAddress: item.data.walletAddress,
// //             stakedAmounts: item.total,
// //             stakingProfit: item.data.profit,
// //             referaalProfit: item.data.refaralProfit,
// //             BlockWithdraw: item.data.blockWithdraw,
// //           }));
// //           const workSheet = XLSX.utils.json_to_sheet(formattedData);
// //           const workBook = XLSX.utils.book_new();
// //           XLSX.utils.book_append_sheet(workBook, workSheet, "users");
// //           //Buffer
// //           let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
// //           //Binary string
// //           XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
// //           //Download
// //           XLSX.writeFile(workBook, "userData.xlsx");
// //           setLoading(false)
// //           setLoading1(false)

// //         })
// //         .catch((res) => {
// //           toast.error(res.data.message);
// //         });
// //     } catch (error) {}
// //   };
// //   useEffect(() => {
// //     if(dataa)
// //     {
// //       getUser();

// //     }
// //     else{
// //       navigate('/sign-in')
// //     }
// //   }, [currentPage]);

// //   return (
// //     <>
// //       <DashboardNavbar />
// //       <DashboardLayout>
// //         {loading ? (
// //           <div className="snippet" data-title=".dot-spin">
// //             <div className="stage">
// //               {loading1 ? (
// //                <div className="dot-spin-container">
// //                <div className="dot-spin"></div>
// //                <div className="dot-spin-text">Downloading file </div>
// //              </div>

// //               ):( <div className="dot-spin"></div> )}
// //             </div>
// //           </div>
// //         ) : (
// //           <MDBox pt={6} pb={3}>
// //             <Grid container spacing={3}>
// //               <Grid item xs={12}>
// //                 <Card>
// //                   <MDBox
// //                     mx={2}
// //                     mt={-3}
// //                     py={3}
// //                     px={2}
// //                     variant="gradient"
// //                     bgColor="coinTitleColor"
// //                     borderRadius="lg"
// //                     coloredShadow="info"
// //                   >
// //                     <div className="box-user">
// //                       <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
// //                         User
// //                       </MDTypography>
// //                       <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
// //                         Total Staked Amount: {totalstaked}
// //                       </MDTypography>
// //                       <MDButton
// //                         onClick={downloadReport}
// //                         variant="contained"
// //                         color="info"
// //                         sx={{ display: "flex", justifyContent: "center", fontSize: "15px" }}
// //                       >
// //                         Download Excel
// //                       </MDButton>
// //                     </div>
// //                   </MDBox>
// //                   <MDBox pt={3}>
// //                   <MDBox pr={1} className=" space-x-2">
// //                   <TextField
// //                     type="text"
// //                     label="Search here"
// //                     onChange={(e) => setSearchTerm(e.target.value.trim())}
// //                     className="p-2"
// //                   />
// //                   <MDButton
// //                   onClick={handleInput}
// //                   variant="contained"
// //                   color="info"
// //                   style={{ height: '30px', marginTop: '5px' }}
// //                 >
// //                   Search
// //                 </MDButton>

// //                 </MDBox>

// //                     <DataTable
// //                       table={{ columns: columns, rows: requestData }}
// //                       // isSorted={true}
// //                       entriesPerPage={false}
// //                       showTotalEntries={false}
// //                       noEndBorder
// //                     />

// //                      {/* {requestData.length > 0 && ( */}
// //                      <Stack padding={2}>
// //                      <Pagination
// //                        onChange={handlePageChange}
// //                        count={page} // Assuming 'page' is the total number of pages
// //                        size="large"
// //                        defaultPage={1} // Set the default highlighted page
// //                        page={currentPage} // Highlight the current page
// //                      />
// //                    </Stack>
// //                     {/* )} */}
// //                   </MDBox>
// //                 </Card>
// //               </Grid>
// //             </Grid>
// //           </MDBox>
// //         )}

// //         <Footer />
// //       </DashboardLayout>

// //       <ToastContainer />
// //     </>
// //   );
// // };

// // export default User;

// import * as XLSX from "xlsx";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import { useNavigate } from "react-router-dom";
// import { useEffect, useRef, useState } from "react";
// import { axiosInstanceAuth } from "../../apiInstances/index";
// import useEncryption from "EncryptDecrypt/EncryptDecrypt";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { TextField } from "@mui/material";
// import MDButton from "components/MDButton";

// import "./user.css";
// import input from "assets/theme/components/form/input";
// import { array } from "prop-types";

// const User = () => {
//   const { encryptData, decryptData } = useEncryption();
//   let navigate = useNavigate();
//   const [searchInput, setSearchInput] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   console.log("🚀 ~ file: index.js:30 ~ User ~ currentPage:", currentPage)
//   const [entriesPerPage] = useState(100);
//   const [page, setPage] = useState(1);
//   console.log("🚀 ~ file: index.js:30 ~ User ~ page:", page)
//   const [requestData, setRequestData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loading1, setLoading1] = useState();
//   const [searchData, setsearchData] = useState()
//   const [searchTerm, setSearchTerm] = useState();

//   // console.log("🚀 ~ file: index.js:33 ~ User ~ loading1:", loading)

//   const [totalstaked, totalStaked] = useState()
//   const viewUser = (data) => {
//     const queryParams = new URLSearchParams({
//       walletAddress: data.data.walletAddress,
//       createdAt: data.data.createdAt,
//       updatedAt: data.data.updatedAt,
//       id: data.data._id,
//     });

//     navigate(`/admin/viewUsers?${queryParams.toString()}`);
//   };
//   const dataa = localStorage.getItem('Token');
//   // console.log("dataa",dataa);
//   const getUser = async () => {
//     setLoading(true)
//     try {
//       axiosInstanceAuth
//         .get(`/usersData/${currentPage}`)
//         .then((res) => {
//           const responseData = decryptData(res?.data?.data);
//           console.log("🚀 ~ file: index.js:54 ~ .then ~ responseData============>:", responseData);
//           const totalPages = responseData?.data?.totalPages;
//           setPage(totalPages)
//           setLoading(false)
//           //  console.log("🚀 ~ file: index.js:57 ~ .then ~ totalPages:", totalPages)

//           const start = (currentPage - 1) * entriesPerPage;
//           const end = start + entriesPerPage;
//           const temp = responseData?.data?.array?.map((data, index) => {
//             // console.log("🚀 ~ file: index.js:59 ~ temp ~ data:", data?.data?.walletAddress)
//             return {

//               id: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {start + index + 1}
//                 </MDTypography>
//               ),
//               wallet_address: (
//                 <div className="userDetails" onClick={() => viewUser(data)}>
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data?.data?.walletAddress} `}
//                   </MDTypography>
//                 </div>
//               ),
//               refered_by: (
//                 <div className="userDetails" onClick={() => viewUser(data)}>
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(-2)} `}
//                   </MDTypography>
//                 </div>
//               ),
//               staked_amounts: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {data?.total}
//                 </MDTypography>
//               ),
//               staking_profit: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.data?.profit}
//                 </MDTypography>
//               ),
//               referaal_profit: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.data?.refaralProfit}
//                 </MDTypography>
//               ),
//               Block_Withdraw: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   <MDButton
//                     variant="gradient"
//                     color="info"
//                     fullWidth
//                     type="submit"
//                     className="TitleColor"
//                     onClick={() => blockWithdraw(data.data._id)}
//                   >
//                     {data?.data?.blockWithdraw}
//                   </MDButton>
//                 </MDTypography>
//               ),

//             };

//           });

//           // console.log("temp---",temp)

//           const calculateTotalStakedAmounts = (data) => {
//             return data.reduce((total, item) => total + item.total, 0);
//           };
//           const totalStakedAmounts = calculateTotalStakedAmounts(responseData.data.array);

//           totalStaked(totalStakedAmounts)

//           setRequestData(temp);
//           setLoading(false);
//           // setPage(Math.ceil(responseData.data.array.length / entriesPerPage));

//         })
//         .catch((error) => {
//           toast.error(error.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const blockWithdraw = async (data) => {
//     try {
//       axiosInstanceAuth
//         .post(`blockWECwithdraw/${data}`)
//         .then(async (res) => {
//           const responseData = decryptData(res?.data?.data);
//           toast.success(responseData.message);
//           getUser();
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const columns = [
//     { Header: "id", accessor: "id", align: "left" },
//     { Header: "wallet_address", accessor: "wallet_address", align: "left" },
//     { Header: "refered_by", accessor: "refered_by", align: "left" },
//     { Header: "staked_amounts", accessor: "staked_amounts", align: "left" },
//     { Header: " staking_profit", accessor: "staking_profit", align: "left" },
//     { Header: "referaal_profit", accessor: "referaal_profit", align: "left" },
//     { Header: "Block_Withdraw", accessor: "Block_Withdraw", align: "left" },
//   ];

//   useEffect(() => {
//     if (searchTerm == "") {
//       getUser();
//     }
//   }, [searchTerm]);

//   const handleInput = () => {

//     try {

//       axiosInstanceAuth.get(`/searchData`, {
//         params: {
//           walletAddress: searchTerm,
//           page: currentPage,
//         },
//       }).then((res) => {
//         const responseData = decryptData(res?.data?.data)
//         setsearchData(responseData)
//         // console.log("🚀 ~ file: index.js:185 ~ handleInput ~ responseData:", responseData)

//         // const searchData = searchInput?.filter((item) => {
//         //   const walletAddress = item?.data?.walletAddress;
//         //   return walletAddress?.includes(inputValue);
//         // });

//         const temp = responseData?.data?.array?.map((data, index) => {
//           return {
//             id: (
//               <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                 {index + 1}
//               </MDTypography>
//             ),
//             wallet_address: (
//               <div className="userDetails" onClick={() => viewUser(data)}>
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {`${data?.data?.walletAddress} `}
//                 </MDTypography>
//               </div>
//             ),
//             refered_by: (
//               <div className="userDetails" onClick={() => viewUser(data)}>
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(-2)} `}
//                 </MDTypography>
//               </div>
//             ),
//             staked_amounts: (
//               <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                 {data?.total}
//               </MDTypography>
//             ),
//             staking_profit: (
//               <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                 {data?.data?.profit}
//               </MDTypography>
//             ),
//             referaal_profit: (
//               <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                 {data?.data?.refaralProfit}
//               </MDTypography>
//             ),
//             Block_Withdraw: (
//               <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                 <MDButton
//                   variant="gradient"
//                   color="info"
//                   fullWidth
//                   type="submit"
//                   className="TitleColor"
//                   onClick={() => blockWithdraw(data?.data?._id)}
//                 >
//                   {data?.data?.blockWithdraw}
//                 </MDButton>
//               </MDTypography>
//             ),
//           };
//         });
//         setRequestData(temp);
//         setPage(1);
//         setCurrentPage(1);
//       })
//     }
//     catch (error) {
//       console.error(error);
//     }

//   };

//   const handlePageChange = (event, newPage) => {
//     if (typeof newPage === "number") {
//       setCurrentPage(newPage);
//       console.log("new page: " + newPage);

//     }
//   };

//   // const downloadReport1 = async () => {
//   //   try {
//   //     await axiosInstanceAuth
//   //       .get(`/withdrawMonths`)
//   //       .then((res) => {
//   //         const responseData = decryptData(res?.data?.data);
//   //         console.log("🚀 ~ .then ~ responseData:---------->>>>", responseData)
//   //         const workSheet = XLSX.utils.json_to_sheet(responseData?.data?.staking);
//   //         const workBook = XLSX.utils.book_new();
//   //         XLSX.utils.book_append_sheet(workBook, workSheet, "users");
//   //         XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
//   //         XLSX.writeFile(workBook, "Withdrow Data.xlsx");
//   //       })
//   //       .catch((res) => {
//   //         toast.error(res?.data?.message);
//   //       });
//   //   } catch (error) { }
//   // };

//   const downloadReport = () => {
//     //  toast.success("Downloading Excel")
//     setLoading(true)
//     setLoading1(true)
//     try {
//       // const encryptedData = encryptData();
//       axiosInstanceAuth
//         .get(`/usersData/${currentPage}`)
//         .then((res) => {
//           const responseData = decryptData(res.data.data);
//           const formattedData = responseData.data.array.map((item) => ({
//             walletAddress: item.data.walletAddress,
//             stakedAmounts: item.total,
//             stakingProfit: item.data.profit,
//             referaalProfit: item.data.refaralProfit,
//             BlockWithdraw: item.data.blockWithdraw,
//           }));
//           const workSheet = XLSX.utils.json_to_sheet(formattedData);
//           const workBook = XLSX.utils.book_new();
//           XLSX.utils.book_append_sheet(workBook, workSheet, "users");
//           //Buffer
//           // let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
//           //Binary string
//           XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
//           //Download
//           XLSX.writeFile(workBook, "userData.xlsx");
//           setLoading(false)
//           setLoading1(false)

//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) { }
//   };
//   useEffect(() => {
//     if (dataa) {
//       getUser();

//     }
//     else {
//       navigate('/sign-in')
//     }
//   }, [currentPage]);

//   return (
//     <>
//       <DashboardNavbar />
//       <DashboardLayout>
//         {loading ? (
//           <div className="snippet" data-title=".dot-spin">
//             <div className="stage">
//               {loading1 ? (
//                 <div className="dot-spin-container">
//                   <div className="dot-spin"></div>
//                   <div className="dot-spin-text">Downloading file </div>
//                 </div>

//               ) : (<div className="dot-spin"></div>)}
//             </div>
//           </div>
//         ) : (
//           <MDBox pt={6} pb={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Card>
//                   <MDBox
//                     mx={2}
//                     mt={-3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="coinTitleColor"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                   >
//                     <div className="box-user">
//                       <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
//                         User
//                       </MDTypography>
//                       <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
//                         Total Staked Amount: {totalstaked}
//                       </MDTypography>
//                       <MDButton
//                         onClick={downloadReport}
//                         variant="contained"
//                         color="info"
//                         sx={{ display: "flex", justifyContent: "center", fontSize: "15px" }}
//                       >
//                         Download Excel
//                       </MDButton>

//                     </div>
//                   </MDBox>
//                   <MDBox pt={3}>
//                     <MDBox pr={1} className=" space-x-2">
//                       <TextField
//                         type="text"
//                         label="Search here"
//                         onChange={(e) => setSearchTerm(e.target.value.trim())}
//                         className="p-2"
//                       />
//                       <MDButton
//                         onClick={handleInput}
//                         variant="contained"
//                         color="info"
//                         style={{ height: '30px', marginTop: '5px' }}
//                       >
//                         Search
//                       </MDButton>

//                     </MDBox>

//                     <DataTable
//                       table={{ columns: columns, rows: requestData }}
//                       // isSorted={true}
//                       entriesPerPage={false}
//                       showTotalEntries={false}
//                       noEndBorder
//                     />

//                     {/* {requestData.length > 0 && ( */}
//                     <Stack padding={2}>
//                       <Pagination
//                         onChange={handlePageChange}
//                         count={page} // Assuming 'page' is the total number of pages
//                         size="large"
//                         defaultPage={1} // Set the default highlighted page
//                         page={currentPage} // Highlight the current page
//                       />
//                     </Stack>
//                     {/* )} */}
//                   </MDBox>
//                 </Card>
//               </Grid>
//             </Grid>
//           </MDBox>
//         )}

//         <Footer />
//       </DashboardLayout>

//       <ToastContainer />
//     </>
//   );
// };

// export default User;

// import * as XLSX from "xlsx";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
// import { useNavigate } from "react-router-dom";
// import { useEffect, useRef, useState } from "react";
// import { axiosInstanceAuth } from "../../apiInstances/index";
// import useEncryption from "EncryptDecrypt/EncryptDecrypt";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { TextField } from "@mui/material";
// import MDButton from "components/MDButton";

// import "./user.css";
// import input from "assets/theme/components/form/input";
// import { array } from "prop-types";

// const User = () => {
//   const { encryptData, decryptData } = useEncryption();
//   let navigate = useNavigate();
//   const [searchInput, setSearchInput] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   console.log("🚀 ~ file: index.js:30 ~ User ~ currentPage:", currentPage)
//   const [entriesPerPage] = useState(100);
//   const [page, setPage] = useState(1);
//   console.log("🚀 ~ file: index.js:30 ~ User ~ page:", page)
//   const [requestData, setRequestData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [loading1, setLoading1] = useState();
//   const [searchData,setsearchData]=useState()
//     const [searchTerm, setSearchTerm] = useState();

//   // console.log("🚀 ~ file: index.js:33 ~ User ~ loading1:", loading)

//   const [totalstaked,totalStaked]=useState()
//   const viewUser = (data) => {
//     const queryParams = new URLSearchParams({
//       walletAddress: data.data.walletAddress,
//       createdAt: data.data.createdAt,
//       updatedAt: data.data.updatedAt,
//       id: data.data._id,
//     });

//     navigate(`/admin/viewUsers?${queryParams.toString()}`);
//   };
//   const dataa= localStorage.getItem('Token');
//   // console.log("dataa",dataa);
//   const getUser = async () => {
//     setLoading(true)
//     try {
//       axiosInstanceAuth
//         .get(`/usersData/${currentPage}`)
//         .then((res) => {
//           const responseData = decryptData(res?.data?.data);
//           // console.log("🚀 ~ file: index.js:54 ~ .then ~ responseData============>:", responseData);
//          const totalPages = responseData?.data?.totalPages;
//          setPage(totalPages)
//          setLoading(false)
//         //  console.log("🚀 ~ file: index.js:57 ~ .then ~ totalPages:", totalPages)

//           const start = (currentPage - 1) * entriesPerPage;
//           const end = start + entriesPerPage;
//           const temp = responseData?.data?.array?.map((data, index) => {
//             // console.log("🚀 ~ file: index.js:59 ~ temp ~ data:", data?.data?.walletAddress)
//             return {

//               id: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {start+ index + 1}
//                 </MDTypography>
//               ),
//               wallet_address: (
//                 <div className="userDetails" onClick={() => viewUser(data)}>
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data?.data?.walletAddress} `}
//                   </MDTypography>
//                 </div>
//               ),
//               refered_by: (
//                 <div className="userDetails" onClick={() => viewUser(data)}>
//                   <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                     {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(-2)} `}
//                   </MDTypography>
//                 </div>
//               ),
//               staked_amounts: (
//                 <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                   {data?.total}
//                 </MDTypography>
//               ),
//               staking_profit: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.data?.profit}
//                 </MDTypography>
//               ),
//               referaal_profit: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   {data?.data?.refaralProfit}
//                 </MDTypography>
//               ),
//               Block_Withdraw: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                   <MDButton
//                     variant="gradient"
//                     color="info"
//                     fullWidth
//                     type="submit"
//                     className="TitleColor"
//                     onClick={() => blockWithdraw(data.data._id)}
//                   >
//                     {data?.data?.blockWithdraw}
//                   </MDButton>
//                 </MDTypography>
//               ),

//             };

//           });

//           // console.log("temp---",temp)

//           const calculateTotalStakedAmounts = (data) => {
//             return data.reduce((total, item) => total + item.total, 0);
//           };
//           const totalStakedAmounts = calculateTotalStakedAmounts(responseData.data.array);

//           totalStaked(totalStakedAmounts)

//           setRequestData(temp);
//           setLoading(false);
//           // setPage(Math.ceil(responseData.data.array.length / entriesPerPage));

//         })
//         .catch((error) => {
//           // toast.error(error.message);
//           toast.error("something went wrong");

//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const blockWithdraw = async (data) => {
//     try {
//       axiosInstanceAuth
//         .post(`blockWECwithdraw/${data}`)
//         .then(async (res) => {
//           const responseData = decryptData(res?.data?.data);
//           toast.success(responseData.message);
//           getUser();
//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const columns = [
//     { Header: "id", accessor: "id", align: "left" },
//     { Header: "wallet_address", accessor: "wallet_address", align: "left" },
//     {Header: "refered_by",accessor: "refered_by", align: "left"},
//     { Header: "staked_amounts", accessor: "staked_amounts", align: "left" },
//     { Header: " staking_profit", accessor: "staking_profit", align: "left" },
//     { Header: "referaal_profit", accessor: "referaal_profit", align: "left" },
//     { Header: "Block_Withdraw", accessor: "Block_Withdraw", align: "left" },
//   ];

//   useEffect(() => {
//     if (searchTerm == "") {
//       getUser();
//     }
//   }, [searchTerm]);

//   const handleInput = () => {

//       try{

//       axiosInstanceAuth.get(`/searchData`, {
//         params: {
//           walletAddress: searchTerm,
//           page: currentPage,
//         },
//       }).then((res)=>{
//         const responseData = decryptData(res?.data?.data)
//         setsearchData(responseData)
//         // console.log("🚀 ~ file: index.js:185 ~ handleInput ~ responseData:", responseData)

//       // const searchData = searchInput?.filter((item) => {
//       //   const walletAddress = item?.data?.walletAddress;
//       //   return walletAddress?.includes(inputValue);
//       // });

//       const temp = responseData?.data?.array?.map((data, index) => {
//         return {
//           id: (
//             <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//               {index + 1}
//             </MDTypography>
//           ),
//           wallet_address: (
//             <div className="userDetails" onClick={() => viewUser(data)}>
//               <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                 {`${data?.data?.walletAddress} `}
//               </MDTypography>
//             </div>
//           ),
//           refered_by: (
//             <div className="userDetails" onClick={() => viewUser(data)}>
//               <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//                 {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(-2)} `}
//               </MDTypography>
//             </div>
//           ),
//           staked_amounts: (
//             <MDTypography component="a" variant="button" color="text" fontWeight="medium">
//               {data?.total}
//             </MDTypography>
//           ),
//           staking_profit: (
//             <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//               {data?.data?.profit}
//             </MDTypography>
//           ),
//           referaal_profit: (
//             <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//               {data?.data?.refaralProfit}
//             </MDTypography>
//           ),
//           Block_Withdraw: (
//             <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//               <MDButton
//                 variant="gradient"
//                 color="info"
//                 fullWidth
//                 type="submit"
//                 className="TitleColor"
//                 onClick={() => blockWithdraw(data?.data?._id)}
//               >
//                 {data?.data?.blockWithdraw}
//               </MDButton>
//             </MDTypography>
//           ),
//         };
//       });
//       setRequestData(temp);
//       setPage(1);
//       setCurrentPage(1);
//     })
//     }
//     catch (error) {
//       console.error(error);
//     }

//   };

//   const handlePageChange = (event,newPage) => {
//     if (typeof newPage === "number") {
//       setCurrentPage(newPage);
//       console.log("new page: " + newPage);

//     }
//   };

//   const downloadReport = () => {
//   //  toast.success("Downloading Excel")
//    setLoading(true)
//    setLoading1(true)
//     try {
//       const encryptedData = encryptData();
//       axiosInstanceAuth
//       .get(`/usersData/${currentPage}`)
//         .then((res) => {
//           const responseData = decryptData(res.data.data);
//           const formattedData = responseData.data.array.map((item) => ({
//             walletAddress: item.data.walletAddress,
//             stakedAmounts: item.total,
//             stakingProfit: item.data.profit,
//             referaalProfit: item.data.refaralProfit,
//             BlockWithdraw: item.data.blockWithdraw,
//           }));
//           const workSheet = XLSX.utils.json_to_sheet(formattedData);
//           const workBook = XLSX.utils.book_new();
//           XLSX.utils.book_append_sheet(workBook, workSheet, "users");
//           //Buffer
//           let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
//           //Binary string
//           XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
//           //Download
//           XLSX.writeFile(workBook, "userData.xlsx");
//           setLoading(false)
//           setLoading1(false)

//         })
//         .catch((res) => {
//           toast.error(res.data.message);
//         });
//     } catch (error) {}
//   };
//   useEffect(() => {
//     if(dataa)
//     {
//       getUser();

//     }
//     else{
//       navigate('/sign-in')
//     }
//   }, [currentPage]);

//   return (
//     <>
//       <DashboardNavbar />
//       <DashboardLayout>
//         {loading ? (
//           <div className="snippet" data-title=".dot-spin">
//             <div className="stage">
//               {loading1 ? (
//                <div className="dot-spin-container">
//                <div className="dot-spin"></div>
//                <div className="dot-spin-text">Downloading file </div>
//              </div>

//               ):( <div className="dot-spin"></div> )}
//             </div>
//           </div>
//         ) : (
//           <MDBox pt={6} pb={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Card>
//                   <MDBox
//                     mx={2}
//                     mt={-3}
//                     py={3}
//                     px={2}
//                     variant="gradient"
//                     bgColor="coinTitleColor"
//                     borderRadius="lg"
//                     coloredShadow="info"
//                   >
//                     <div className="box-user">
//                       <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
//                         User
//                       </MDTypography>
//                       <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
//                         Total Staked Amount: {totalstaked}
//                       </MDTypography>
//                       <MDButton
//                         onClick={downloadReport}
//                         variant="contained"
//                         color="info"
//                         sx={{ display: "flex", justifyContent: "center", fontSize: "15px" }}
//                       >
//                         Download Excel
//                       </MDButton>
//                     </div>
//                   </MDBox>
//                   <MDBox pt={3}>
//                   <MDBox pr={1} className=" space-x-2">
//                   <TextField
//                     type="text"
//                     label="Search here"
//                     onChange={(e) => setSearchTerm(e.target.value.trim())}
//                     className="p-2"
//                   />
//                   <MDButton
//                   onClick={handleInput}
//                   variant="contained"
//                   color="info"
//                   style={{ height: '30px', marginTop: '5px' }}
//                 >
//                   Search
//                 </MDButton>

//                 </MDBox>

//                     <DataTable
//                       table={{ columns: columns, rows: requestData }}
//                       // isSorted={true}
//                       entriesPerPage={false}
//                       showTotalEntries={false}
//                       noEndBorder
//                     />

//                      {/* {requestData.length > 0 && ( */}
//                      <Stack padding={2}>
//                      <Pagination
//                        onChange={handlePageChange}
//                        count={page} // Assuming 'page' is the total number of pages
//                        size="large"
//                        defaultPage={1} // Set the default highlighted page
//                        page={currentPage} // Highlight the current page
//                      />
//                    </Stack>
//                     {/* )} */}
//                   </MDBox>
//                 </Card>
//               </Grid>
//             </Grid>
//           </MDBox>
//         )}

//         <Footer />
//       </DashboardLayout>

//       <ToastContainer />
//     </>
//   );
// };

// export default User;

import * as XLSX from "xlsx";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { axiosInstanceAuth } from "../../apiInstances/index";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, ButtonGroup, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { FaDownload } from "react-icons/fa6";

import "./user.css";
import input from "assets/theme/components/form/input";
import { array } from "prop-types";

const User = () => {
  const { encryptData, decryptData } = useEncryption();
  let navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  console.log("🚀 ~ file: index.js:30 ~ User ~ currentPage:", currentPage);
  const [entriesPerPage] = useState(100);
  const [page, setPage] = useState(1);
  console.log("🚀 ~ file: index.js:30 ~ User ~ page:", page);
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState();
  const [searchData, setsearchData] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [selectedButton, setSelectedButton] = useState("WEC");


  // console.log("🚀 ~ file: index.js:33 ~ User ~ loading1:", loading)

  const [totalWECstaked, settotalWECStaked] = useState();
  const [totalABTstaked, settotalABTStaked] = useState();


  const viewUser = (data) => {
    const queryParams = new URLSearchParams({
      walletAddress: data.data.walletAddress,
      createdAt: data.data.createdAt,
      updatedAt: data.data.updatedAt,
      id: data.data._id,
    });

    navigate(`/admin/viewUsers?${queryParams.toString()}`);
  };
  const dataa = localStorage.getItem("Token");
  // console.log("dataa",dataa);
  const getUser = async () => {
    setLoading(true);
    try {
      axiosInstanceAuth
        .get(`/usersData/${currentPage}`)
        .then((res) => {
          const responseData = decryptData(res?.data?.data);
          console.log("🚀 ~ .then ~ responseData:", responseData);
          const totalPages = responseData?.data?.totalPages;
          const totalWECStaked = String(responseData?.data?.totalWECStaked).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
          const totalABTStaked = String(responseData?.data?.totalABTStaked).replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );

          setPage(totalPages);
          setLoading(false);
          settotalWECStaked(totalWECStaked);
          settotalABTStaked(totalABTStaked);
          const start = (currentPage - 1) * entriesPerPage;
          const end = start + entriesPerPage;
          const temp = responseData?.data?.array?.map((data, index) => {
            return {
              id: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {start + index + 1}
                </MDTypography>
              ),
              wallet_address: (
                <div className="userDetails" onClick={() => viewUser(data)}>
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data?.data?.walletAddress} `}
                  </MDTypography>
                </div>
              ),
              refered_by: (
                <div className="userDetails" onClick={() => viewUser(data)}>
                  <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                    {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(
                      -2
                    )} `}
                  </MDTypography>
                </div>
              ),
              ABTstaked_amounts: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data?.StakedABT}
                </MDTypography>
              ),
              staked_amounts: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data?.StakedWEC}
                </MDTypography>
              ),
              staking_profit: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data?.data?.profit.toFixed(2)}
                </MDTypography>
              ),
              ABTstaking_profit: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data?.data?.ABTStakingProfit.toFixed(2)}
                </MDTypography>
              ),
              referaal_profit: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data?.data?.refaralProfit.toFixed(2)}
                </MDTypography>
              ),
              ABTreferaal_profit: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  {data?.data?.ABTStakingRefaralProfit.toFixed(2)}
                </MDTypography>
              ),
              unstaked_amounts: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data?.allUnstedWecHistoryData}
                </MDTypography>
              ),
              ABTunstaked_amounts: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {data?.allUnstedABTHistoryData}
                </MDTypography>
              ),
              withdrawFees: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {(
                    (data?.withdrawFees?.WECRewards ?? 0) + (data?.withdrawFees?.WECEarning ?? 0)
                  ).toFixed(2)}
                  ({data?.withdrawFees?.WECRewards.toFixed(2)}+{data?.withdrawFees?.WECEarning.toFixed(2)})
                </MDTypography>
              ),
              withdrawABTFees: (
                <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                  {(
                    (data?.AbtwithdrawFees?.ABTRewards ?? 0) +
                    (data?.AbtwithdrawFees?.ABTEarning ?? 0)
                  ).toFixed(2)}
                  ({data?.AbtwithdrawFees?.ABTRewards}+{data?.AbtwithdrawFees?.ABTEarning})
                </MDTypography>
              ),
              Block_Withdraw: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                    className="TitleColor"
                    onClick={() => blockWithdraw(data.data._id)}
                  >
                    {data?.data?.blockWithdraw}
                  </MDButton>
                </MDTypography>
              ),
            };
          });

          setRequestData(temp);
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const blockWithdraw = async (data) => {
    try {
      axiosInstanceAuth
        .post(`blockWECwithdraw/${data}`)
        .then(async (res) => {
          const responseData = decryptData(res?.data?.data);
          toast.success(responseData.message);
          getUser();
        })
        .catch((res) => {
          toast.error(res.data.message);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "wallet_address", accessor: "wallet_address", align: "left" },
    { Header: "refered_by", accessor: "refered_by", align: "left" },
    { Header: "staked_amounts", accessor: "staked_amounts", align: "left" },
    { Header: " staking_profit", accessor: "staking_profit", align: "left" },
    { Header: "referaal_profit", accessor: "referaal_profit", align: "left" },
    { Header: "withdrawFees (rewards + earning)", accessor: "withdrawFees", align: "left" },

    { Header: "unstaked_amounts", accessor: "unstaked_amounts", align: "left" },

    { Header: "Block_Withdraw", accessor: "Block_Withdraw", align: "left" },
  ];
  const columnsABT = [
    { Header: "id", accessor: "id", align: "left" },
    { Header: "wallet_address", accessor: "wallet_address", align: "left" },
    { Header: "refered_by", accessor: "refered_by", align: "left" },
    { Header: "ABTstaked_amounts", accessor: "ABTstaked_amounts", align: "left" },
    { Header: "ABTstaking_profit", accessor: "ABTstaking_profit", align: "left" },
    { Header: "ABTreferaal_profit", accessor: "ABTreferaal_profit", align: "left" },
    { Header: "withdrawABTFees (rewards + earning)", accessor: "withdrawABTFees", align: "left" },

    { Header: "ABTunstaked_amounts", accessor: "ABTunstaked_amounts", align: "left" },

    { Header: "Block_Withdraw", accessor: "Block_Withdraw", align: "left" },
  ];


  useEffect(() => {
    if (searchTerm == "") {
      getUser();
    }
  }, [searchTerm]);

  const handleInput = (e) => {
    const searchdata = e.target.value.trim();
    setSearchTerm(searchdata);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newDebounceTimeout = setTimeout(async () => {
      if (searchData === "") {
        getUser();
      } else {
        try {
          axiosInstanceAuth
            .get(`/searchData`, {
              params: {
                walletAddress: searchdata,
                page: currentPage,
              },
            })
            .then((res) => {
              const responseData = decryptData(res?.data?.data);
              console.log("🚀 ~ .then ~ responseData:", responseData);
              setsearchData(responseData);

              const temp = responseData?.data?.array?.map((data, index) => {
                return {
                  id: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {index + 1}
                    </MDTypography>
                  ),
                  wallet_address: (
                    <div className="userDetails" onClick={() => viewUser(data)}>
                      <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                        {`${data?.data?.walletAddress} `}
                      </MDTypography>
                    </div>
                  ),
                  refered_by: (
                    <div className="userDetails" onClick={() => viewUser(data)}>
                      <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                        {`${data?.data?.referedBy?.slice(0, 3)}....${data?.data?.referedBy?.slice(
                          -2
                        )} `}
                      </MDTypography>
                    </div>
                  ),
                  ABTstaked_amounts: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data?.StakedABT}
                    </MDTypography>
                  ),
                  staked_amounts: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data?.StakedWEC}
                    </MDTypography>
                  ),
                  staking_profit: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.data?.profit}
                    </MDTypography>
                  ),
                  ABTstaking_profit: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.data?.ABTStakingProfit}
                    </MDTypography>
                  ),
                  withdrawFees: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {(
                        (data?.withdrawFees?.WECRewards ?? 0) +
                        (data?.withdrawFees?.WECEarning ?? 0)
                      ).toFixed(2)}
                      ({data?.withdrawFees?.WECRewards}+{data?.withdrawFees?.WECEarning})
                    </MDTypography>
                  ),
                  withdrawABTFees: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {(
                        (data?.AbtwithdrawFees?.ABTRewards ?? 0) +
                        (data?.AbtwithdrawFees?.ABTEarning ?? 0)
                      ).toFixed(2)}
                      ({data?.AbtwithdrawFees?.ABTRewards}+{data?.AbtwithdrawFees?.ABTEarning})
                    </MDTypography>
                  ),
                  referaal_profit: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.data?.refaralProfit}
                    </MDTypography>
                  ),
                  ABTreferaal_profit: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      {data?.data?.ABTStakingRefaralProfit}
                    </MDTypography>
                  ),
                  unstaked_amounts: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data?.allUnstedWecHistoryData}
                    </MDTypography>
                  ),
                  ABTunstaked_amounts: (
                    <MDTypography component="a" variant="button" color="text" fontWeight="medium">
                      {data?.allUnstedABTHistoryData}
                    </MDTypography>
                  ),
                  Block_Withdraw: (
                    <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        className="TitleColor"
                        onClick={() => blockWithdraw(data?.data?._id)}
                      >
                        {data?.data?.blockWithdraw}
                      </MDButton>
                    </MDTypography>
                  ),
                };
              });
              setRequestData(temp);
              setPage(1);
              setCurrentPage(1);
            });
        } catch (error) {
          console.error(error);
        }
      }
    }, 500);

    setDebounceTimeout(newDebounceTimeout);
  };

  const handlePageChange = (event, newPage) => {
    if (typeof newPage === "number") {
      setCurrentPage(newPage);
      console.log("new page: " + newPage);
    }
  };

  const downloadReport = async () => {
    setLoading1(true);
    try {
      let page = 1;
      console.log("🚀 ~ downloadReport ~ page:", page)
      let allData = [];
      console.log("🚀 ~ downloadReport ~ allData:", allData)
      console.log("🚀 ~ downloadReport ~ allData:", allData)

      while (true) {
        const response = await axiosInstanceAuth.get(`/usersData/${page}`);
        const responseData = decryptData(response?.data?.data);
        console.log("🚀 ~ downloadReport ~ responseData:", responseData);

        if (!responseData?.data || responseData.data.array.length === 0) {
          // No more data to fetch, break the loop
          break;
        }

        allData = [...allData, ...responseData?.data?.array];
        page++;
      }

      console.log("🚀 ~ downloadReport ~ allData:", allData);
      const WECformattedData = allData.map((item) => ({
        walletAddress: item.data.walletAddress,
        refered_by: item.data.referedBy,
        stakedAmounts: item.StakedWEC,
        stakingProfit: item.data.profit,
        referaalProfit: item.data.refaralProfit,
        unstakeAmount: item.allUnstedWecHistoryData,
        WithdrawFees: (item?.withdrawFees?.WECRewards ?? 0) + (item?.withdrawFees?.WECEarning ?? 0),
        BlockWithdraw: item.data.blockWithdraw,
      }));

      const ABTformattedData = allData.map((item) => ({
        walletAddress: item.data.walletAddress,
        refered_by: item.data.referedBy,
        stakedAmounts: item.StakedABT,
        stakingProfit: item.data.ABTStakingProfit,
        referaalProfit: item.data.ABTStakingRefaralProfit,
        unstakeAmount: item.allUnstedABTHistoryData,
        WithdrawFees:
          (item?.AbtwithdrawFees?.ABTRewards ?? 0) + (item?.AbtwithdrawFees?.ABTEarning ?? 0),
        BlockWithdraw: item.data.blockWithdraw,
      }));

      const workSheet = XLSX.utils.json_to_sheet(
        selectedButton === "WEC" ? WECformattedData : ABTformattedData
      );
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "users");

      let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workBook, selectedButton === "WEC" ? "wecData.xlsx" : "abtData.xlsx");
      setLoading(false);
      setLoading1(false);
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  useEffect(() => {
    if (dataa) {
      getUser();
    } else {
      navigate("/sign-in");
    }
  }, [currentPage]);





  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        {loading ? (
          <div className="snippet" data-title=".dot-spin">
            <div className="stage">
              <div className="dot-spin"></div>
            </div>
          </div>
        ) : (
          <>
            <div className="text-center  mt-5">
              <ButtonGroup variant="contained" color="primary">
                <Button
                  onClick={() => setSelectedButton("WEC")}
                  variant={selectedButton === "WEC" ? "contained" : "outlined"}
                  style={{
                    backgroundColor: selectedButton === "WEC" ? "blue" : "white",
                    color: selectedButton === "WEC" ? "white" : "blue",
                  }}
                >
                  WEC
                </Button>
                <Button
                  onClick={() => setSelectedButton("ABT")}
                  variant={selectedButton === "ABT" ? "contained" : "outlined"}
                  style={{
                    backgroundColor: selectedButton === "ABT" ? "blue" : "white",
                    color: selectedButton === "ABT" ? "white" : "blue",
                  }}
                >
                  ABT
                </Button>
              </ButtonGroup>
            </div>
            <MDBox pt={6} pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="coinTitleColor"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <div className="box-user">
                        <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
                          User
                        </MDTypography>
                        <MDTypography variant="h6" color="white" sx={{ fontSize: "24px" }}>
                          Total Staked Amount: {selectedButton === "WEC" ? totalWECstaked : totalABTstaked}
                        </MDTypography>
                        {loading1 ? (
                          <>
                            <div className="flex justify-center items-center gap-2">
                              <div className="text-yellow-200 font-bold mr-5">
                                It takes a few time due to heavy load.{" "}
                              </div>
                              <div className="dot-spin1"></div>
                              <MDButton
                                // onClick={downloadReport}
                                variant="contained"
                                color="info"
                                sx={{ display: "flex", justifyContent: "center" }}
                                disabled={loading1}
                              >
                                Downloading ..
                              </MDButton>
                            </div>
                          </>
                        ) : (
                          <>
                            <MDButton
                              onClick={downloadReport}
                              variant="contained"
                              color="info"
                              sx={{ display: "flex", justifyContent: "center", fontSize: "15px" }}
                            >
                              Download Excel
                            </MDButton>
                          </>
                        )}
                      </div>
                    </MDBox>
                    <MDBox pt={3}>
                      <MDBox pr={1} className=" space-x-2">
                        <TextField
                          type="text"
                          label="Search here"
                          onChange={handleInput}
                          className="p-2"
                        />
                      </MDBox>

                      <DataTable
                        table={{ columns: selectedButton === "WEC" ? columns : columnsABT, rows: requestData }}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        noEndBorder
                      />

                      <Stack padding={2}>
                        <Pagination
                          onChange={handlePageChange}
                          count={page} // Assuming 'page' is the total number of pages
                          size="large"
                          defaultPage={1} // Set the default highlighted page
                          page={currentPage} // Highlight the current page
                        />
                      </Stack>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </>
        )}

        <Footer />
      </DashboardLayout>

      <ToastContainer />
    </>
  );
};

export default User;
