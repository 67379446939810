import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Box from "@mui/material/Box";
import { useState } from "react";
import { FormControl } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import MDButton from "components/MDButton";
import { ButtonGroup, Button } from "@mui/material";
import axios from "axios";
import useEncryption from "EncryptDecrypt/EncryptDecrypt";
import { axiosInstance } from "apiInstances";

function Interest() {
  const [walletaddress, Setwalletaddress] = useState();
  const [amount, Setamount] = useState();
  const [duration, SetDuration] = useState("12");
  // const [userId, SetUserId] = useState("");
  const { encryptData, decryptData } = useEncryption();

  // console.log(walletaddress, amount, duration);
  // console.log("userId", userId);

  const getUserId = async (id) => {
    Setwalletaddress(id);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log("btn clicked");

    try {
      const res = await axiosInstance.get(`/walletToID/${walletaddress.toLowerCase()}`);
      const responseData = decryptData(res?.data?.data);
      console.log("🚀 ~ file: index.js:37 ~ submitHandler ~ responseData:", responseData)
    
      if (responseData.status==true) {
       
        addUser(responseData?.data?.userId);
      } else {
        toast.error(responseData?.message);
      }
      Setamount("")
      Setwalletaddress("")
    } catch (e) {
      console.log(e);
    }
  };
  const addUser = async (userId) => {
    try {
      console.log("userId",userId);
      const walletAD = walletaddress;
      const encrypt1 = encryptData(
        JSON.stringify({
          userId: userId,
          amount: amount,
          duration: duration,
          walletAddress: walletAD.toLowerCase(),
        })
      );
      console.log("🚀 ~ file: index.js:57 ~ addUser ~ encrypt1:", decryptData(encrypt1))
      // console.log("encrypt1", dec);
      const resultstaking = await axiosInstance.post("/staking", {
        // data: encrypt1,
        userId: userId,
        amount: amount,
        duration: duration,
        walletAddress: walletAD.toLowerCase(),
      });

      const resultsstaking = decryptData(resultstaking.data.data);
      console.log("resultsstaking 2===============>", resultsstaking);
      if (resultsstaking.status) {
        const encrypt = encryptData(
          JSON.stringify({
            userId: userId,
            amount: amount,
            walletAddress: walletAD.toLowerCase(),
          })
        );
        console.log("🚀 ~ file: index.js:77 ~ addUser ~ encrypt:", decryptData(encrypt))
        await axiosInstance
          .post("/refralDistribution", {
            // data: encrypt,
            userId: userId,
            amount: amount,
            walletAddress: walletAD.toLowerCase(),
          })
          .then((res) => {
            const responseData = decryptData(res?.data?.data);
            console.log("🚀 ~ file: index.js:83  3 ============>~ .then ~ responseData:", responseData)
            // console.log("responseData", responseData);
            if(responseData.status==true)
            {
              toast.success(`${responseData?.message} ${amount}`);
            }
            else{
              toast.error(responseData?.message)
            }
            Setamount("")
            Setwalletaddress("")
          });
      } else {
        toast.error(resultsstaking.message);
      }
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  return (
    <>
      <DashboardNavbar />
      <DashboardLayout>
        <MDBox pt={6} pb={3}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              py={2}
              px={2}
              textAlign="center"
              className="TitleColor heading-width"
            >
              <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                Manually Add User
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Box>
                <FormControl onSubmit={submitHandler}>
                  <label htmlFor="walletaddress"> Wallet Address</label>
                  <input
                    type="text"
                    id="interestRate"
                    name="interestRate"
                    // Define step as needed
                    className="border border-black rounded-md mb-5"
                    // onChange={(e) => Setwalletaddress(e.target.value)}
                    onChange={(e) => getUserId(e.target.value)}
                    placeholder="0x251286fej820eo7f39c"
                    value={walletaddress}
                    required
                  />
                  <label htmlFor="amount"> Amount</label>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    // Define step as needed
                    className="border border-black rounded-md mb-5"
                    onChange={(e) => Setamount(e.target.value)}
                    placeholder="enter amount"
                    value={amount}
                    required
                  />
                  <label htmlFor="duration">Choose Node(duration)</label>
                  <div className="text-center  mt-5">
                    <ButtonGroup variant="contained" color="primary">
                      <Button
                        onClick={() => SetDuration("12")}
                        variant={duration === "12" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: duration === "12" ? "blue" : "white",
                          color: duration === "12" ? "white" : "blue",
                        }}
                      >
                        12 months
                      </Button>
                      <Button
                        onClick={() => SetDuration("6")}
                        variant={duration === "6" ? "contained" : "outlined"}
                        style={{
                          backgroundColor: duration === "6" ? "blue" : "white",
                          color: duration === "6" ? "white" : "blue",
                        }}
                      >
                        6 months
                      </Button>
                    </ButtonGroup>
                  </div>
                  <MDBox component="form" role="form">
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        type="submit"
                        className="TitleColor"
                      >
                        Submit
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </FormControl>
              </Box>
            </MDBox>
          </Card>
        </MDBox>
        <Footer />
      </DashboardLayout>
      <ToastContainer />
    </>
  );
}
export default Interest;
